import { type Veranstaltung } from '../../../dtos';
import { trpc } from '../../../trpc';
import { experteName } from '../../../utils/experteUtils';
import { getOrtKuerzel } from '../../../utils/getBezeichnungen';
import { getVeranstaltungStart } from '../../../utils/getVeranstaltungStartEnd';
import { type TypedGridColdDef } from '../../../utils/gridHelper';
import { AccordionWithHeading } from '../../general/AccordionWithHeading';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import { deDE } from '@mui/x-data-grid-pro/locales';

type VeranstaltungenUebersichtCardProps = {
  readonly themaId: number;
};

type VeranstaltungMapped = {
  id: number;
  start: Date;
  ortKuerzel: string;
  experte: string;
  status: string;
};

const columns: Array<TypedGridColdDef<VeranstaltungMapped>> = [
  { field: 'start', headerName: 'Startdatum', type: 'date', flex: 1 },
  { field: 'ortKuerzel', headerName: 'Ortkürzel', renderCell: (params) => params.row.ortKuerzel },
  { field: 'experte', headerName: 'Trainer:in', flex: 1 },
  { field: 'status', headerName: 'Status', flex: 0.7 },
];

export const VeranstaltungenUebersichtCard: React.FC<VeranstaltungenUebersichtCardProps> = (props: VeranstaltungenUebersichtCardProps) => {
  const veranstaltungen = trpc.veranstaltung.getVeranstaltungenByThemaId.useQuery({ themaId: props.themaId });

  return (
    <AccordionWithHeading heading="Veranstaltungen">
      <DataGridPro
        disableRowSelectionOnClick
        disableColumnMenu
        initialState={{
          sorting: {
            sortModel: [{ field: 'start', sort: 'desc' }],
          },
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        pagination
        pageSizeOptions={[5, 10, 25, 50]}
        columns={columns}
        rows={veranstaltungsMapper(veranstaltungen.data)}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
          },
        }}
        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
      />
    </AccordionWithHeading>
  );
};

function veranstaltungsMapper(veranstaltungen: Veranstaltung[] | undefined): VeranstaltungMapped[] {
  const mappedVeranstaltungen: VeranstaltungMapped[] = [];

  if (typeof veranstaltungen === 'undefined') {
    return mappedVeranstaltungen;
  }

  for (const veranstaltung of veranstaltungen) {
    const veranstaltungStart = getVeranstaltungStart(veranstaltung);

    const firstExperteBlockung = veranstaltung.experteBlockungen.at(0);

    mappedVeranstaltungen.push({
      id: veranstaltung.id,
      start: veranstaltungStart,
      ortKuerzel: getOrtKuerzel(veranstaltung) ?? '-',
      experte: firstExperteBlockung ? experteName(firstExperteBlockung.experte) : '-',
      status: veranstaltung.vivaStatus,
    });
  }

  return mappedVeranstaltungen;
}
