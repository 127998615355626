import { ressourcenBlockungTypes } from '../../../../../../../dtos';
import { AbstractCalendarEvent, type BlockungData } from './AbstractCalendarEvent';

/**
 * Represents one Raumblockung, and one event in the calendar
 */
export class CalendarRaumEvent extends AbstractCalendarEvent {
  public readonly type = ressourcenBlockungTypes.RAUM;

  public readonly blockungData: BlockungData;

  public constructor(
    start: Date,
    end: Date, // don't import the real CalendarResource type here - otherwise, there will by cyclic includes
    calendarResource: {
      id: `r-${string}`;
      getRessourceSapId: () => number;
    },
    raumBlockungData: BlockungData,
  ) {
    super(start, end, calendarResource);

    this.blockungData = raumBlockungData;
  }
}
