import { trpc } from '../../../../../../trpc';
import { type FilterParameters } from '../CalendarResourceOverview.utils';
import { Autocomplete, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';

type FilterMenuBarProps = {
  readonly onFilterChange: (filter: FilterParameters) => void;
  readonly filterParameters?: FilterParameters;
  readonly interval: { start: Date; end: Date };
};

export const FilterMenuBar: React.FC<FilterMenuBarProps> = (props: FilterMenuBarProps) => {
  const { onFilterChange, filterParameters: inputFilterParams, interval } = props;

  const [filterParameters, setFilterParameters] = useState<FilterParameters>(
    inputFilterParams ?? {
      region: [],
      standort: [],
      orte: [],
    },
  );

  const availableRegions = trpc.planung.resources.getAvailableRegionen.useQuery();
  const availableStandorte = trpc.planung.resources.getAvailableStandorteByRegion.useQuery({ regionSapIds: [] });
  const availableOrte = trpc.planung.resources.getOrteWithRaeume.useQuery({
    regionSapIds: filterParameters.region,
    standortSapIds: filterParameters.standort,
    ortSapIds: [],
    start: interval.start,
    end: interval.end,
  });

  const updateFilterValue = (key: keyof FilterParameters, value: string | string[] | number | number[] | boolean): void => {
    setFilterParameters((prev) => {
      const newState = {
        ...prev,
        [key]: value,
      };
      onFilterChange(newState);

      return newState;
    });
  };

  return (
    <Stack direction="row" display="flex" spacing={2} justifyContent="space-evenly">
      <FormControl fullWidth>
        <InputLabel size="small">Region</InputLabel>
        <Select size="small" multiple label="Region" onChange={(event) => updateFilterValue('region', event.target.value)} value={filterParameters.region}>
          {availableRegions.data?.map((region) => (
            <MenuItem key={region.regionSapId} value={region.regionSapId}>
              {region.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel size="small">Standort</InputLabel>
        <Select size="small" multiple label="Standort" onChange={(event) => updateFilterValue('standort', event.target.value)} value={filterParameters.standort}>
          {availableStandorte.data?.map((standort) => (
            <MenuItem key={standort.standortSapId} value={standort.standortSapId}>
              {standort.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <Autocomplete
          id="ort-input"
          size="small"
          multiple
          onChange={(_, newValues) => {
            updateFilterValue(
              'orte',
              newValues.map((ort) => ort.ortSapId),
            );
          }}
          options={availableOrte.data ?? []}
          getOptionLabel={(opt) => opt.kuerzel}
          renderInput={(params) => <TextField {...params} label="Ort (Hotel)" />}
        />
      </FormControl>
    </Stack>
  );
};
