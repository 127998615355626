import { type Veranstaltung } from '../dtos';

const getStandort = (veranstaltung: Veranstaltung) => veranstaltung.standortSlotBlockungen.at(0)?.standort;

const getOrt = (veranstaltung: Veranstaltung) => veranstaltung.raumBlockungen.at(0)?.raum.ort;

export const getOrtBezeichnung = (veranstaltung: Veranstaltung): string | undefined => {
  const standort = getStandort(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.name ? ort.name : (standort?.name ?? undefined);
};

export const getOrtKuerzel = (veranstaltung: Veranstaltung): string | undefined => {
  const standort = getStandort(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.kuerzel ?? standort?.kuerzel ?? undefined;
};

export const getStandortName = (veranstaltung: Veranstaltung): string | undefined => {
  const standort = getStandort(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.standort?.name ?? standort?.name ?? undefined;
};

export const getStandortKuerzel = (veranstaltung: Veranstaltung): string | undefined => {
  const standort = getStandort(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.standort?.kuerzel ?? standort?.kuerzel ?? undefined;
};

export const getRegionName = (veranstaltung: Veranstaltung): string | undefined => {
  const standort = getStandort(veranstaltung);
  const ort = getOrt(veranstaltung);

  return ort?.region?.name ?? standort?.region?.name ?? undefined;
};
