import { ressourcenBlockungTypes } from '../../../../../../../dtos';
import { type CalendarExperteResource } from './CalendarExperteResource';
import { type CalendarRaumResource } from './CalendarRaumResource';
import { type CalendarStandortResource } from './CalendarStandortResource';

export type CalendarResource = CalendarExperteResource | CalendarRaumResource | CalendarStandortResource;

export const isExperteResource = (resourceData: CalendarResource): resourceData is CalendarExperteResource => resourceData.type === ressourcenBlockungTypes.EXPERTE;
export const isRaumResource = (resourceData: CalendarResource): resourceData is CalendarRaumResource => resourceData.type === ressourcenBlockungTypes.RAUM;
export const isStandortResource = (resourceData: CalendarResource): resourceData is CalendarStandortResource => resourceData.type === ressourcenBlockungTypes.STANDORT;
