import { type Ablauf, ressourcenBlockungTypes, type Standort } from '../../../../../../../dtos';
import { type TerminInfo } from '../../../../../../../utils/TerminInfo';
import { CalendarStandortEvent } from '../CalendarEvent';
import { AbstractCalendarResource } from './AbstractCalendarResource';
import { addDays, endOfDay, startOfDay } from 'date-fns';

/**
 * Represents one Standort and one row in the calendar.
 */
export class CalendarStandortResource extends AbstractCalendarResource {
  public readonly type = ressourcenBlockungTypes.STANDORT;

  public readonly id: `s-${string}`;

  public readonly name: string;

  public readonly standort: Standort;

  public constructor(terminInfo: TerminInfo, standort: Standort) {
    super(terminInfo);
    this.standort = standort;
    this.id = `s-${standort.standortSapId}`;
    this.name = standort.name;
  }

  public convertBlockungenToCalendarEvents(): CalendarStandortEvent[] {
    const events: CalendarStandortEvent[] = [];

    for (const blockung of this.standort.standortSlotBlockungen) {
      const start = this.getBlockungStart(blockung.zeitraeume);
      const end = this.getBlockungEnd(blockung.zeitraeume);

      const raumgroesse = blockung.veranstaltung.thema.planungsinformation?.raumgroesse;

      const newEvent = new CalendarStandortEvent(start, end, this, {
        akaVeranstaltungId: blockung.veranstaltung.akaVeranstaltungId,
        geschaeftsbereich: blockung.veranstaltung.geschaeftsbereich,
        veranstaltungId: blockung.veranstaltungId,
        zeitraeume: blockung.zeitraeume,
        gruppenraumNotwendig: blockung.veranstaltung.thema.planungsinformation?.gruppenraumNotwendig ?? null,
        raumgroesse: typeof raumgroesse === 'undefined' ? null : raumgroesse,
        standortName: this.standort.name,
      });

      newEvent.isOldSelection = blockung.veranstaltung.akaVeranstaltungId === this.terminInfo.akaVeranstaltungId;

      events.push(newEvent);
    }

    return events;
  }

  public createNewCalendarEvent(date: Date): CalendarStandortEvent {
    const start = startOfDay(date);
    const end = endOfDay(addDays(date, this.terminInfo.ablauf.length - 1));

    const zeitraeume: Ablauf = [];
    for (const [index] of this.terminInfo.ablauf.entries()) {
      zeitraeume.push({
        start: startOfDay(addDays(date, index)),
        end: endOfDay(addDays(date, index)),
      });
    }

    const event = new CalendarStandortEvent(start, end, this, {
      akaVeranstaltungId: this.terminInfo.akaVeranstaltungId,
      geschaeftsbereich: this.terminInfo.geschaeftsbereich,
      veranstaltungId: this.terminInfo.veranstaltungId,
      zeitraeume,
      standortName: this.standort.name,
      // it's not necessary to take the following infos from the actual thema / planungsinformation, since they are only used for showing information, not storing information
      gruppenraumNotwendig: [],
      raumgroesse: 0,
    });

    return event;
  }

  public getRessourceSapId(): number {
    return this.standort.standortSapId;
  }
}
