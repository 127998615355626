import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {
  type Clusterzuordnung,
  clusterzuordnung,
  type GetPlanungsinformationOutput,
  type RessourcenBlockungen,
  type RessourcenBlockungType,
  ressourcenBlockungTypes,
  terminart,
  type VeranstaltungCustomProperties,
} from '../../../../../dtos';
import { trpc } from '../../../../../trpc';
import { getBackendConfig } from '../../../../../utils/BackendInfrastructure';
import { type TerminInfo } from '../../../../../utils/TerminInfo';
import {
  type CollapseInfo,
  convertCurrentlySelectedCalendarEventsToBlockungen,
  createCalendarColors,
  createCalendarResources,
  type FilterParameters,
  groupCalendarResources,
} from './CalendarResourceOverview.utils';
import { type CalendarEvent, CalendarEventHelper, type CalendarResource } from './helperClasses';
import { DisplayCalendarEvent, DisplayCalendarResource, FilterMenuBar, FullScreen, LegendChips } from './helperComponents';
import { VeranstaltungPropertiesChanger } from './helperComponents/VeranstaltungPropertiesChanger';
import { Eventcalendar, localeDe, type MbscCellClickEvent, type MbscEventClickEvent, setOptions } from '@mobiscroll/react';
import { Cancel, Fullscreen, Save } from '@mui/icons-material';
import { Button, Divider, Grid2, Stack, Tooltip, Typography } from '@mui/material';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import { type ReactElement, useEffect, useMemo, useState } from 'react';

setOptions({
  locale: localeDe,
  theme: 'material',
  themeVariant: 'light',
});

type CalendarResourceOverviewProps = {
  readonly isFullscreen: boolean;
  readonly toggleFullscreen: () => void;
  readonly onSave: (blockungen: RessourcenBlockungen, properties?: VeranstaltungCustomProperties) => Promise<void>;
  readonly onCancel: () => void | Promise<void>;
  readonly terminInfo: TerminInfo;
  readonly initDate?: Date;
  readonly themaId: number;
};

export const CalendarResourceOverview: React.FC<CalendarResourceOverviewProps> = ({
  onSave,
  onCancel,
  initDate,
  terminInfo,
  isFullscreen,
  toggleFullscreen,
  themaId,
}: CalendarResourceOverviewProps) => {
  const [filterParams, setFilterParams] = useState<FilterParameters>({
    region: [],
    standort: [],
    orte: [],
  });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);

  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [resources, setResources] = useState<CalendarResource[]>([]);
  const [collapseInfos, setCollapseInfos] = useState<CollapseInfo>({ experte: false, raum: false, standort: false });
  const [timeRange, setTimeRange] = useState<{ start: Date; end: Date }>({
    start: startOfMonth(initDate ?? new Date()),
    end: endOfMonth(initDate ?? new Date()),
  });

  const [hiveUrl, setHiveUrl] = useState('');

  const [veranstaltungCustomProperties, setVeranstaltungCustomProperties] = useState<VeranstaltungCustomProperties>({
    terminart: terminart.STANDARDTERMIN,
    manuelleTerminanfrage: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  const planungsinformationQuery = trpc.planung.getPlanungsinformation.useQuery<GetPlanungsinformationOutput>({ themaId });
  useEffect(() => {
    if (planungsinformationQuery.data) {
      setVeranstaltungCustomProperties((val) => ({ ...val, manuelleTerminanfrage: planungsinformationQuery.data.manuelleTerminanfrage }));
    }
  }, [planungsinformationQuery.data]);

  const availableStandorte = trpc.planung.resources.getAvailableStandorteByRegion.useQuery(
    {
      regionSapIds: filterParams.region,
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const standortSlots = trpc.planung.resources.getStandorte.useQuery(
    {
      ...timeRange,
      standortSapIds: filterParams.standort,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const expertePool = trpc.planung.resources.getExpertenByThema.useQuery(
    {
      themaId: terminInfo.themaId,
      ...timeRange,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const orteWithRooms = trpc.planung.resources.getOrteWithRaeume.useQuery(
    {
      regionSapIds: filterParams.region,
      standortSapIds: filterParams.standort,
      ortSapIds: filterParams.orte,
      ...timeRange,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const calendarEventHelper = useMemo(() => new CalendarEventHelper(terminInfo.ablauf), [terminInfo]);

  useEffect(() => {
    const setUrl = async (): Promise<void> => {
      const config = await getBackendConfig();
      if (config.success) {
        setHiveUrl(config.config.HiveAppUrl ?? '');
      }
    };

    void setUrl();
  }, []);

  useEffect(() => {
    if (expertePool.data && orteWithRooms.data && standortSlots.data) {
      const newResources = createCalendarResources(expertePool.data, orteWithRooms.data, standortSlots.data, terminInfo, timeRange.start);
      setResources(newResources);
      const initEvents = calendarEventHelper.initEvents(newResources);

      setEvents(initEvents);
    }
  }, [expertePool.data, orteWithRooms.data, standortSlots.data, terminInfo, calendarEventHelper, timeRange.start]);

  useEffect(() => {
    const ressourcenBlockungen = convertCurrentlySelectedCalendarEventsToBlockungen(events);

    const raumBlockungExists = ressourcenBlockungen.some((resource) => resource.type === ressourcenBlockungTypes.RAUM);
    const standortBlockungExists = ressourcenBlockungen.some((resource) => resource.type === ressourcenBlockungTypes.STANDORT);

    if (!raumBlockungExists && !standortBlockungExists) {
      setIsSaveButtonDisabled(true);
    } else {
      setIsSaveButtonDisabled(false);
    }
  }, [calendarEventHelper, events, filterParams]);

  const onCalendarCellClicked = (args: MbscCellClickEvent): void => {
    const resourceId = args.resource;

    // this is what happens if a day in one of the row groups (Räume, Standorte, Trainer) is clicked
    if (Object.values(ressourcenBlockungTypes).includes(resourceId as RessourcenBlockungType)) {
      return;
    }

    // this is what happens if a day in one of the sub row groups in Trainer (Haupttraienr, Nebentrainer, Ersatztrainer) is clicked
    if (Object.values(clusterzuordnung).includes(resourceId as Clusterzuordnung)) {
      return;
    }

    const resource = resources.find((res) => res.id === resourceId);
    if (!resource) {
      enqueueSnackbar({ variant: 'error', message: 'Ressource nicht gefunden.' });
      return;
    }

    setEvents((prev) => {
      const res = calendarEventHelper.addEvent(resource, events, args.date);
      if (res instanceof Error) {
        enqueueSnackbar({ variant: 'error', message: res.message });
        return prev;
      }

      return res;
    });
  };

  const onCalendarEventClicked = (mbscEvent: MbscEventClickEvent): void => {
    // the eventId is, for our calendar events, always a string (see AbstractCalendarEvent)
    // however, Mobiscroll does not know this -> this function serves as a type safety wrapper
    const eventId = mbscEvent.event.id;
    if (typeof eventId === 'undefined' || typeof eventId === 'number') {
      // this implies that someone played around with the calendar events and did not update this code
      console.warn('Unknown calendar event id type');
      return;
    }

    setEvents(calendarEventHelper.removeEvent(eventId, events));
  };

  const handleSave = async (): Promise<void> => {
    const ressourcenBlockungen = convertCurrentlySelectedCalendarEventsToBlockungen(events);

    setEvents([]);
    await onSave(ressourcenBlockungen, veranstaltungCustomProperties);
  };

  const handleCancel = async (): Promise<void> => {
    setEvents([]);
    await onCancel();
  };

  if (planungsinformationQuery.error || expertePool.error || availableStandorte.error || standortSlots.error || orteWithRooms.error) {
    return <p>Wir haben technische Probleme :(</p>;
  }

  if (planungsinformationQuery.isLoading || !planungsinformationQuery.data || availableStandorte.isLoading || !availableStandorte.data) {
    return <p>Lädt ...</p>;
  }

  const handleExpansionChange = (resourceType: RessourcenBlockungType, collapsed: boolean): void => {
    setCollapseInfos((prev) => {
      switch (resourceType) {
        case ressourcenBlockungTypes.EXPERTE:
          return { ...prev, experte: collapsed };
        case ressourcenBlockungTypes.RAUM:
          return { ...prev, raum: collapsed };
        case ressourcenBlockungTypes.STANDORT:
          return { ...prev, slot: collapsed };
        default:
          return prev;
      }
    });
  };

  const displaySaveTooltip = (): string | undefined =>
    isSaveButtonDisabled ? 'Sie müssen einen Raum oder einen Standort auswählen, um die Veranstaltung speichern zu können!' : undefined;

  const renderActionButtons = (small?: boolean): ReactElement => (
    <Grid2 size={{ xs: small ? 6 : 12 }} display="flex" justifyContent="right" gap={5}>
      <Button onClick={handleCancel} size="small" startIcon={<Cancel />} color="error" sx={{ borderRadius: 1 }} variant="outlined">
        Abbrechen
      </Button>
      <Button size="small" startIcon={<Fullscreen />} sx={{ borderRadius: 1 }} variant="outlined" onClick={toggleFullscreen}>
        {isFullscreen ? 'Ansicht min' : 'Ansicht max'}
      </Button>
      <Tooltip arrow placement="top" title={displaySaveTooltip()}>
        <div>
          <Button startIcon={<Save />} size="small" onClick={handleSave} color="success" variant="contained" disabled={isSaveButtonDisabled} sx={{ borderRadius: 1, boxShadow: 1 }}>
            Speichern
          </Button>
        </div>
      </Tooltip>
    </Grid2>
  );

  return (
    <FullScreen isFullscreen={isFullscreen} withoutCloseButton>
      <Grid2 container padding={2} spacing={2}>
        {!isFullscreen && renderActionButtons(false)}

        {!isFullscreen && (
          <>
            <Grid2 size={{ xs: 12 }} sx={{ marginBottom: 0.5, marginTop: 0.5 }}>
              <Divider />
            </Grid2>

            <VeranstaltungPropertiesChanger
              themaManuelleTerminanfrage={planungsinformationQuery.data.manuelleTerminanfrage}
              handleAttributeChange={(properties: VeranstaltungCustomProperties) => {
                setVeranstaltungCustomProperties(properties);
              }}
            />

            <Grid2 size={{ xs: 12 }} sx={{ marginBottom: 0.5, marginTop: 0.5 }}>
              <Divider />
            </Grid2>
          </>
        )}

        <Grid2 size={{ xs: 6 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Ressourcenübersicht</Typography>
        </Grid2>

        {isFullscreen && renderActionButtons(true)}
        <Grid2 size={{ xs: 12 }}>
          <FilterMenuBar
            interval={timeRange}
            onFilterChange={async (filter) => {
              setFilterParams(filter);
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <LegendChips />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Eventcalendar
            colors={createCalendarColors(resources, events)}
            data={events}
            resources={groupCalendarResources(resources, collapseInfos, planungsinformationQuery.data)}
            defaultSelectedDate={initDate ?? new Date()}
            locale={localeDe}
            onCellClick={onCalendarCellClicked}
            onEventClick={onCalendarEventClicked}
            onPageChange={({ firstDay, lastDay }) => setTimeRange({ start: firstDay, end: lastDay })}
            onResourceExpand={(resourceInfo) => handleExpansionChange(resourceInfo.resource as RessourcenBlockungType, false)}
            onResourceCollapse={(resourceInfo) => handleExpansionChange(resourceInfo.resource as RessourcenBlockungType, true)}
            showEventTooltip={false}
            renderResource={(resource: CalendarResource) => <DisplayCalendarResource resource={resource} startTime={timeRange.start} hiveUrl={hiveUrl} />}
            event
            renderScheduleEvent={(event) => <DisplayCalendarEvent event={event.original as CalendarEvent} />}
            theme="material"
            themeVariant="light"
            height={isFullscreen ? '80vh' : '70vh'}
            view={{
              timeline: {
                type: 'month',
                weekNumbers: true,
                startDay: 1,
                endDay: 5,
              },
            }}
          />
        </Grid2>
      </Grid2>
    </FullScreen>
  );
};
