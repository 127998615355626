import { type Ablauf, type AkaVeranstaltungId, type Geschaeftsbereich, type RessourcenBlockungType } from '../../../../../../../dtos';
import { type MbscCalendarEvent } from '@mobiscroll/react';
import { v7 } from 'uuid';

export type BlockungData = {
  akaVeranstaltungId: AkaVeranstaltungId | null;
  geschaeftsbereich: Geschaeftsbereich | null;
  veranstaltungId: number | null;
  zeitraeume: Ablauf;
};

/**
 * Represents one Blockung of a Ressource in our system (either Raumblockung or Experteblockung or Standortblockung), and one event in the calendar.
 */
export abstract class AbstractCalendarEvent implements MbscCalendarEvent {
  /**
   * Type of the Blockung.
   */
  public abstract readonly type: RessourcenBlockungType;

  /**
   * Data of the Blockung - depending on the type, additional data might be stored here.
   */
  public abstract readonly blockungData: BlockungData;

  /*
   * This is the id of the CalendarResource that created this event.
   * See AbstractCalendarResource for more information.
   * Needed by Mobiscroll -> Don't rename.
   */
  public readonly resource: string;

  /**
   * Unique event identifier.
   * Needed by Mobiscroll -> Don't rename.
   */
  public readonly id: string = v7();

  /*
   * This is the sapId of the Ressource of the Blockung.
   * Needed by Mobiscroll -> Don't rename.
   */
  public readonly ressourceSapId: number;

  /**
   * We want all events to take up the entire space of the field that it is in, therefore, this value is always set to true.
   * Needed by Mobiscroll -> Don't rename.
   */
  public readonly allDay: boolean = true;

  /**
   * Start of the Blockung.
   * Needed by Mobiscroll -> Don't rename.
   */
  public start: Date;

  /**
   * End of the Blockung.
   * Needed by Mobiscroll -> Don't rename.
   */
  public end: Date;

  public isCurrentSelection: boolean = false;

  public isOldSelection: boolean = false;

  public constructor(
    start: Date,
    end: Date,
    // don't import the real CalendarResource type here - otherwise, there will by cyclic includes
    calendarResource: {
      id: string;
      getRessourceSapId: () => number;
    },
  ) {
    this.resource = calendarResource.id;
    this.ressourceSapId = calendarResource.getRessourceSapId();
    this.start = start;
    this.end = end;
  }
}
