import colorPalette from '../../../../../../theme/colorPalette';
import { Chip, Stack, Typography } from '@mui/material';

export const LegendChips: React.FC = () => (
  <Stack alignItems="center" direction="row" spacing={2}>
    <Typography variant="body1">Legende:</Typography>
    <Chip label="buchbare Zeiträume" sx={{ backgroundColor: colorPalette.colors.lightgreen, color: 'inherit' }} />
    <Chip label="Externe Blockung" sx={{ backgroundColor: colorPalette.warning.main, color: 'white' }} />
    <Chip label="VIVA-Blockung" sx={{ backgroundColor: colorPalette.colors.crimson, color: 'white' }} />
    <Chip label="aktuelle Auswahl" sx={{ backgroundColor: colorPalette.primary.main, color: 'white' }} />
    <Chip label="bisherige Auswahl" sx={{ backgroundColor: colorPalette.monochrome.grey60, color: 'white' }} />
  </Stack>
);
