import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Stack, styled } from '@mui/material';
import { forwardRef, type ReactNode } from 'react';

type FullScreenProps = {
  readonly isFullscreen: boolean;
  readonly onClose?: () => void;
  readonly children: ReactNode;
  readonly withoutCloseButton?: boolean;
};

const Backdrop = forwardRef<HTMLDivElement, { readonly open?: boolean; readonly className: string }>((props, ref) => {
  const { open, className, ...other } = props;
  return <div className={[open ? 'MuiBackdrop-open' : '', className].filter(Boolean).join(' ')} ref={ref} {...other} />;
});

const ModalContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.mode === 'dark' ? '#1C2025' : '#FFF'};
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434D5B' : '#DAE2ED'};
  box-shadow: 0px 4px 12px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.20)'};
  color: ${theme.palette.mode === 'dark' ? '#F3F6F9' : '#1C2025'};
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
  text-align: start;
  position: relative;


  & .modal-title {
    margin: 0;
    line-height: 1.5rem;
    margin-right: 0.5rem;
  }

  & .modal-description {
    margin: 0;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${theme.palette.mode === 'dark' ? '#B0B8C4' : '#303740'};
  }
  `,
);

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

export const FullScreen: React.FC<FullScreenProps> = ({ isFullscreen, children, onClose, withoutCloseButton }: FullScreenProps) => {
  if (isFullscreen) {
    return (
      <Modal onClose={onClose} open={isFullscreen} slots={{ backdrop: StyledBackdrop }} sx={{ height: '100vh', width: '100vw' }}>
        <ModalContent sx={{ height: '97%', margin: 'auto', padding: 1 }}>
          {withoutCloseButton ? null : (
            <Stack direction="row" justifyContent="start">
              <IconButton onClick={onClose}>
                {' '}
                <Close />
              </IconButton>
            </Stack>
          )}
          {children}
        </ModalContent>
      </Modal>
    );
  } else {
    return children;
  }
};
