import './index.css';
import App from './App';
import { AzureApplication } from './auth/AzureApplication';
import { ErrorPage } from './pages/ErrorPage';
import { getBackendConfig } from './utils/BackendInfrastructure';
import { LicenseInfo } from '@mui/x-license';
import { setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

LicenseInfo.setLicenseKey('4b0bf0b29e186d35239b5f1e0d2ff8bcTz05MDM3OSxFPTE3NDc0MDU4NTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

try {
  const res = await getBackendConfig();

  const msalInstance = new AzureApplication(res);

  // set default locale for date-fns - saves us from having to set the locale each time :)
  setDefaultOptions({ locale: de });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  createRoot(document.querySelector('#root')!).render(
    <React.StrictMode>
      <App msalInstance={msalInstance} />
    </React.StrictMode>,
  );
} catch (error: unknown) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  createRoot(document.querySelector('#root')!).render(
    <React.StrictMode>
      <ErrorPage error={error} />
    </React.StrictMode>,
  );
}
