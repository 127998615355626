import { Button, type ButtonProps } from '@mui/material';

type EventButtonProps = Pick<ButtonProps, 'onClick' | 'children' | 'sx'> & { readonly eventColor: string };

export const EventButton: React.FC<EventButtonProps> = ({ onClick, eventColor, children, sx }: EventButtonProps) => (
  <Button
    onClick={onClick}
    variant="contained"
    size="small"
    sx={{ ...sx, borderRadius: 1, width: '100%', minHeight: '1.5rem', color: 'white !important', backgroundColor: eventColor }}
  >
    {children}
  </Button>
);
