import { ressourcenBlockungTypes } from '../../../../../../../dtos';
import { AbstractCalendarEvent, type BlockungData } from './AbstractCalendarEvent';

type CalendarStandortEventData = BlockungData & {
  gruppenraumNotwendig: number[] | null;
  raumgroesse: number | null;
  standortName: string;
};

/**
 * Represents one Standortblockung, and one event in the calendar
 */
export class CalendarStandortEvent extends AbstractCalendarEvent {
  public readonly type = ressourcenBlockungTypes.STANDORT;

  public readonly blockungData: CalendarStandortEventData;

  public constructor(
    start: Date,
    end: Date, // don't import the real CalendarResource type here - otherwise, there will by cyclic includes
    calendarResource: {
      id: `s-${string}`;
      getRessourceSapId: () => number;
    },
    standortBlockungData: CalendarStandortEventData,
  ) {
    super(start, end, calendarResource);

    this.blockungData = standortBlockungData;
  }
}
