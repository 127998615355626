import { type Terminart, terminart as terminartEnum, type VeranstaltungCustomProperties } from '../../../../../../dtos.js';
import { Box, FormControlLabel, Grid2, Radio, RadioGroup, Switch, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

type VeranstaltungPropertiesChangerProps = {
  readonly themaManuelleTerminanfrage: boolean;
  readonly handleAttributeChange: (propertyData: VeranstaltungCustomProperties) => void;
};

export const VeranstaltungPropertiesChanger: React.FC<VeranstaltungPropertiesChangerProps> = (props: VeranstaltungPropertiesChangerProps) => {
  const { themaManuelleTerminanfrage, handleAttributeChange } = props;

  const [terminart, setTerminart] = useState<Terminart>(terminartEnum.STANDARDTERMIN);
  const [manuelleTerminanfrage, setManuelleTerminanfrage] = useState<boolean>(themaManuelleTerminanfrage);

  useEffect(() => {
    setManuelleTerminanfrage(themaManuelleTerminanfrage);
  }, [themaManuelleTerminanfrage]);

  const handleTerminartChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newTerminart = (event.target as HTMLInputElement).value as Terminart;
    let newManuelleTerminanfrage = themaManuelleTerminanfrage;
    if (newTerminart === terminartEnum.ZUSATZTERMIN) {
      newManuelleTerminanfrage = true;
    }

    setTerminart(newTerminart);
    setManuelleTerminanfrage(newManuelleTerminanfrage);

    handleAttributeChange({ terminart: newTerminart, manuelleTerminanfrage: newManuelleTerminanfrage });
  };

  const showTerminanfrageTooltip = (): string => {
    if (terminart === terminartEnum.STANDARDTERMIN) {
      return 'Das Flag wird aus dem Thema vererbt und kann bei Standardterminen nicht geändert werden.';
    } else {
      return 'Terminanfragen werden bei Zusatzterminen manuell durchgeführt und das Flag kann nicht verändert werden.';
    }
  };

  return (
    <Grid2 container size={{ xs: 12 }} gap={2}>
      <Grid2 size={{ xs: 6 }}>
        <Typography sx={{ fontWeight: 'bold' }}>Angaben zur Veranstaltung</Typography>
      </Grid2>

      <Grid2 size={{ xs: 12 }} gap={10} display="flex">
        <Box>
          Terminart
          <Box>
            <RadioGroup value={terminart} name="terminart-radio-buttons" onChange={handleTerminartChange}>
              <Tooltip
                disableInteractive
                title={<Typography variant="body2">Standardtermine sind Termine, die innerhalb der regulären halbjährigen Planungsphase angelegt werden.</Typography>}
              >
                <FormControlLabel value={terminartEnum.STANDARDTERMIN} control={<Radio />} label="Standardtermin" />
              </Tooltip>
              <Tooltip
                disableInteractive
                title={
                  <Typography variant="body2">Zusatztermine sind Termine, die außerhalb der regulären Planungsphase, meist kurzfristig, zusätzlich angelegt werden.</Typography>
                }
              >
                <FormControlLabel value={terminartEnum.ZUSATZTERMIN} control={<Radio />} label="Zusatztermin" />
              </Tooltip>
            </RadioGroup>
          </Box>
        </Box>
        <Box>
          Terminanfrage
          <Box>
            <Tooltip disableInteractive title={<Typography variant="body2">{showTerminanfrageTooltip()}</Typography>}>
              <FormControlLabel
                control={<Switch sx={{ m: 1 }} disabled checked={manuelleTerminanfrage} />}
                label="Manuelle Terminanfrage"
                sx={{ '& > span': { color: 'unset !important' } }}
              />
            </Tooltip>
          </Box>
        </Box>
      </Grid2>
    </Grid2>
  );
};
