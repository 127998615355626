import { MenuItem, Select, Typography } from '@mui/material';
import { type Dispatch, type ReactElement, type SetStateAction } from 'react';
import { v4 } from 'uuid';

type TextOrSelectProps<T extends string> = {
  readonly isEdit: boolean;
  readonly valueState: [T, Dispatch<SetStateAction<T>>];
  readonly selections: Array<{ value: T; name: string }>;
};

export const TextOrSelect = <T extends string>(props: TextOrSelectProps<T>): ReactElement<TextOrSelectProps<T>> => {
  const [value, setValue] = props.valueState;

  if (!props.isEdit) {
    return <Typography component="span">{props.selections.find((sel) => sel.value === value)?.name ?? '-'}</Typography>;
  }

  return (
    <Select
      value={value}
      variant="standard"
      size="small"
      onChange={(event) => {
        setValue(event.target.value as T);
      }}
    >
      {props.selections.map((sel) => (
        <MenuItem key={v4()} value={sel.value}>
          {sel.name}
        </MenuItem>
      ))}
    </Select>
  );
};
