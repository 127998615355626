import { experteBlockungQuelle, type ExperteFromResourcesRouter, ressourcenBlockungTypes } from '../../../../../../../dtos';
import { type TerminInfo } from '../../../../../../../utils/TerminInfo';
import { CalendarExperteEvent } from '../CalendarEvent';
import { AbstractCalendarResource } from './AbstractCalendarResource';
import { endOfDay, startOfDay } from 'date-fns';

/**
 * Represents one Experte, and one row in the calendar.
 */
export class CalendarExperteResource extends AbstractCalendarResource {
  public readonly type = ressourcenBlockungTypes.EXPERTE;

  public readonly id: `e-${string}`;

  public name: string;

  public readonly experte: ExperteFromResourcesRouter;

  public constructor(terminInfo: TerminInfo, experte: ExperteFromResourcesRouter, year: number) {
    super(terminInfo);
    this.id = `e-${experte.experteSapId}`;
    const workloadSelectedYear = experte.experteAuslastungen.find((wl) => wl.jahr === year);
    const workloadSelectedYearString = workloadSelectedYear ? ` / (${Math.round(workloadSelectedYear.summeTage)} / ${workloadSelectedYear.schwelleRot})` : ' / ( - / - )';
    this.name = `${experte.vorname} ${experte.nachname}${workloadSelectedYearString}`;
    this.experte = experte;
  }

  public convertBlockungenToCalendarEvents(): CalendarExperteEvent[] {
    const events: CalendarExperteEvent[] = [];

    for (const blockung of this.experte.experteBlockungen) {
      const start = this.getBlockungStart(blockung.zeitraeume);
      const end = this.getBlockungEnd(blockung.zeitraeume);

      const newEvent = new CalendarExperteEvent(start, end, this, {
        akaVeranstaltungId: blockung.veranstaltung?.akaVeranstaltungId ?? null,
        veranstaltungId: blockung.veranstaltungId,
        zeitraeume: blockung.zeitraeume,
        geschaeftsbereich: blockung.geschaeftsbereich,
        quelle: blockung.quelle,
        quelleTerminId: blockung.quelleTerminId,
      });
      newEvent.isOldSelection = blockung.quelleTerminId === this.terminInfo.akaVeranstaltungId;

      events.push(newEvent);
    }

    return events;
  }

  public createNewCalendarEvent(date: Date): CalendarExperteEvent {
    // with experten, at first click, only one day is selectable -> this is why this implementation of createEvent behaves differently
    const start = startOfDay(date);
    const end = endOfDay(date);
    const event = new CalendarExperteEvent(start, end, this, {
      akaVeranstaltungId: this.terminInfo.akaVeranstaltungId,
      veranstaltungId: this.terminInfo.veranstaltungId,
      zeitraeume: [{ start, end }],
      geschaeftsbereich: this.terminInfo.geschaeftsbereich,
      quelle: experteBlockungQuelle.VAMOS,
      quelleTerminId: this.terminInfo.akaVeranstaltungId,
    });
    return event;
  }

  public getRessourceSapId(): number {
    return this.experte.experteSapId;
  }
}
