import { ressourcenBlockungTypes } from '../../../../../../../dtos';
import { type CalendarExperteEvent } from './CalendarExperteEvent';
import { type CalendarRaumEvent } from './CalendarRaumEvent';
import { type CalendarStandortEvent } from './CalendarStandortEvent';

export const isRaumEvent = (event: CalendarEvent): event is CalendarRaumEvent => event.type === ressourcenBlockungTypes.RAUM;
export const isExperteEvent = (event: CalendarEvent): event is CalendarExperteEvent => event.type === ressourcenBlockungTypes.EXPERTE;
export const isStandortEvent = (event: CalendarEvent): event is CalendarStandortEvent => event.type === ressourcenBlockungTypes.STANDORT;

export type CalendarEvent = CalendarExperteEvent | CalendarRaumEvent | CalendarStandortEvent;
