import { type ThemaFromThemaRouter, type Veranstaltung, vivaStatus } from '../../../../dtos';
import { experteName } from '../../../../utils/experteUtils';
import { AccordionWithHeading } from '../../../general/AccordionWithHeading';
import { Tablelike } from '../../../general/Tablelike';
import { BlockungenTextStack } from '../BlockungenTextStack';
import { UpdateRessourcenBlockungen } from '../UpdateRessourcenBlockungen';
import { StatusTerminanfrageHive } from './StatusTerminanfrageHive';
import { content } from './TerminExperteCard.content';
import { Draggable } from '@mobiscroll/react';
import { Stack } from '@mui/material';
import { v4 } from 'uuid';

type TerminExperteCardProps = {
  readonly veranstaltung: Veranstaltung;
  readonly thema: ThemaFromThemaRouter;
};

export const TerminExperteCard: React.FC<TerminExperteCardProps> = ({ veranstaltung, thema }: TerminExperteCardProps) => (
  <Draggable id={content.draggableId}>
    <AccordionWithHeading heading={content.heading}>
      <Stack spacing={1}>
        {veranstaltung.vivaStatus === vivaStatus.INPLANUNG && (
          <UpdateRessourcenBlockungen
            terminInfo={{
              veranstaltungId: veranstaltung.id,
              akaVeranstaltungId: veranstaltung.akaVeranstaltungId,
              themaId: thema.id,
              geschaeftsbereich: veranstaltung.geschaeftsbereich,
              ablauf: veranstaltung.ablauf,
            }}
          />
        )}
        {veranstaltung.experteBlockungen.map((experteBlockung) => (
          <Tablelike
            key={experteBlockung.experteSapId}
            withDivider
            rows={[
              [content.fields.name, <b key={v4()}>{experteName(experteBlockung.experte)}</b>],
              [content.fields.rolle, content.noInfoAvailable],
              [content.fields.sapId, experteBlockung.experteSapId],
              [content.fields.zeitraum, <BlockungenTextStack key={v4()} zeitraeume={experteBlockung.zeitraeume} />],
              [content.fields.kommunikationspartner, content.noInfoAvailable],
              [content.fields.manuelleTerminanfrage, veranstaltung.manuelleTerminanfrage ? content.ja : content.nein],
              [content.fields.statusTerminanfrageHive, <StatusTerminanfrageHive experteBlockung={experteBlockung} key={v4()} />],
            ]}
          />
        ))}
      </Stack>
    </AccordionWithHeading>
  </Draggable>
);
