import { type GridFilterModelExtended, gridFilterModelExtendedSchema } from '../../../../utils/MuiSchemas';

export const persistDataGridModel = (type: 'filter' | 'sort', model: unknown): void => {
  localStorage.setItem(`/thema:${type}`, JSON.stringify(model));
};

export const loadFilterModel = (): GridFilterModelExtended | undefined => {
  const filterModel = localStorage.getItem('/thema:filter');
  return filterModel ? gridFilterModelExtendedSchema.parse(JSON.parse(filterModel)) : undefined;
};
