import { type FachlicherFehler } from '../../dtos';
import { trpc } from '../../trpc';
import { Alert, Box } from '@mui/material';
import { DataGridPro, gridClasses, type GridColDef } from '@mui/x-data-grid-pro';
import { deDE } from '@mui/x-data-grid-pro/locales';

const columns: Array<GridColDef<FachlicherFehler>> = [
  { field: 'createdAt', type: 'dateTime', headerName: 'Fehler-Zeitstempel', flex: 0.5 },
  { field: 'veranstaltungBeginn', type: 'date', headerName: 'Veranstaltung-Beginn', flex: 0.6 },
  { field: 'veranstaltungSapId', headerName: 'Sap-E-Id', flex: 0.5 },
  { field: 'akaVeranstaltungId', headerName: 'AKA-VA-Id', flex: 0.5 },
  { field: 'quelle', headerName: 'Quelle', flex: 0.5, sortable: false },
  { field: 'veranstaltungTitel', headerName: 'Titel', flex: 1, sortable: false },
  { field: 'geschaeftsbereich', headerName: 'Geschäftsbereich', flex: 0.5, sortable: false },
  { field: 'code', headerName: 'Fehler-Typ', flex: 0.6, sortable: false },
  { field: 'message', headerName: 'Fehler-Beschreibung', flex: 1, sortable: false },
];

export const FehlerPage: React.FC = () => {
  const fehlerResult = trpc.fehler.getFachlicheFehler.useQuery();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: 2,
        padding: 2,
        boxSizing: 'border-box',
      }}
    >
      <Box sx={{ flexGrow: 1, minHeight: 0 }}>
        <DataGridPro
          columns={columns}
          rows={fehlerResult.data ?? []}
          getRowHeight={() => 'auto'}
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
          }}
          sx={{
            height: '100%',
            width: '100%',
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none',
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: 'none',
            },
          }}
          disableRowSelectionOnClick
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      <Alert severity="info">
        Die Fehlertabelle enthält nur Fehler von Veranstaltungen, die noch relevant sind. Fehler werden folglich nur bis zu einer Woche nach Veranstaltungsbeginn angezeigt.
      </Alert>
    </Box>
  );
};
