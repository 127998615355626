import { type Ablauf, type Raum, ressourcenBlockungTypes } from '../../../../../../../dtos';
import { type TerminInfo } from '../../../../../../../utils/TerminInfo';
import { CalendarRaumEvent } from '../CalendarEvent';
import { AbstractCalendarResource } from './AbstractCalendarResource';
import { addDays, eachDayOfInterval, endOfDay, isWithinInterval, startOfDay } from 'date-fns';

/**
 * Represents one Raum, and one row in the calendar.
 */
export class CalendarRaumResource extends AbstractCalendarResource {
  public readonly type = ressourcenBlockungTypes.RAUM;

  public readonly id: `r-${string}`;

  public readonly name: string;

  public readonly raum: Raum;

  public readonly ortName: string;

  public readonly standortName: string | null;

  public readonly regionName: string | null;

  public constructor(terminInfo: TerminInfo, raum: Raum, ortName: string, standortName: string | null, regionName: string | null) {
    super(terminInfo);
    this.raum = raum;
    this.id = `r-${raum.raumSapId}`;
    this.name = `${raum.kuerzel} / ${raum.name} / ${raum.groesse} m² / ${raum.kapazitaetUForm}-${raum.kapazitaetParlamentarisch}-${raum.kapazitaetOhneTische}`;
    this.ortName = ortName;
    this.standortName = standortName;
    this.regionName = regionName;
  }

  public convertBlockungenToCalendarEvents(): CalendarRaumEvent[] {
    const events: CalendarRaumEvent[] = [];

    for (const blockung of this.raum.raumBlockungen) {
      const start = this.getBlockungStart(blockung.zeitraeume);
      const end = this.getBlockungEnd(blockung.zeitraeume);

      const newEvent = new CalendarRaumEvent(start, end, this, {
        akaVeranstaltungId: blockung.akaVeranstaltungId,
        geschaeftsbereich: blockung.veranstaltung?.geschaeftsbereich ?? null,
        veranstaltungId: blockung.veranstaltungId,
        zeitraeume: blockung.zeitraeume,
      });

      newEvent.isOldSelection = blockung.akaVeranstaltungId === this.terminInfo.akaVeranstaltungId;

      events.push(newEvent);
    }

    return events;
  }

  public createNewCalendarEvent(date: Date): CalendarRaumEvent {
    const start = startOfDay(date);
    const end = endOfDay(addDays(date, this.terminInfo.ablauf.length - 1));

    const zeitraeume: Ablauf = [];
    for (const [index] of this.terminInfo.ablauf.entries()) {
      zeitraeume.push({
        start: startOfDay(addDays(date, index)),
        end: endOfDay(addDays(date, index)),
      });
    }

    return new CalendarRaumEvent(start, end, this, {
      akaVeranstaltungId: this.terminInfo.akaVeranstaltungId,
      geschaeftsbereich: this.terminInfo.geschaeftsbereich,
      veranstaltungId: this.terminInfo.veranstaltungId,
      zeitraeume,
    });
  }

  public getRessourceSapId(): number {
    return this.raum.raumSapId;
  }

  public isAvailable(interval: { start: Date; end: Date }) {
    const days = eachDayOfInterval(interval);
    return days.every((date) => this.raum.gekaufterZeitraum.some((tag) => isWithinInterval(date, { start: startOfDay(tag.start), end: endOfDay(tag.end) })));
  }
}
