import { loginRequest } from './config';
import { type IPublicClientApplication } from '@azure/msal-browser';

export const getIdToken = (instance: IPublicClientApplication) => async (): Promise<string> => {
  const account = instance.getActiveAccount();

  if (account === null) {
    return '';
  } else {
    try {
      const authResult = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      return authResult.idToken;
    } catch {
      await instance.loginRedirect(loginRequest);
      const authResult = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      return authResult.idToken;
    }
  }
};
