import { type ExperteBlockungQuelle, ressourcenBlockungTypes } from '../../../../../../../dtos';
import { AbstractCalendarEvent, type BlockungData } from './AbstractCalendarEvent';

type CalendarExperteEventData = BlockungData & {
  quelle: ExperteBlockungQuelle;
  quelleTerminId: string | null;
};

/**
 * Represents one Experteblockung, and one event in the calendar
 */
export class CalendarExperteEvent extends AbstractCalendarEvent {
  public readonly type = ressourcenBlockungTypes.EXPERTE;

  public readonly blockungData: CalendarExperteEventData;

  public constructor(
    start: Date,
    end: Date, // don't import the real CalendarResource type here - otherwise, there will by cyclic includes
    calendarResource: {
      id: `e-${string}`;
      getRessourceSapId: () => number;
    },
    experteBlockungData: CalendarExperteEventData,
  ) {
    super(start, end, calendarResource);

    this.blockungData = experteBlockungData;
  }
}
