import { type Veranstaltung } from '../../../dtos';
import { experteName } from '../../../utils/experteUtils';
import { getOrtBezeichnung, getOrtKuerzel, getRegionName } from '../../../utils/getBezeichnungen';
import { VaStatusChip } from '../../general/VaStatusChip';
import { Apartment, CalendarToday, Groups2, Person, Place } from '@mui/icons-material';
import { Paper, Stack, Typography } from '@mui/material';

type TerminInfoCardProps = {
  readonly veranstaltung: Veranstaltung;
};

export const TerminInfoCard: React.FC<TerminInfoCardProps> = ({ veranstaltung }: TerminInfoCardProps) => {
  const firstExperteBlockung = veranstaltung.experteBlockungen.at(0);

  return (
    <Paper elevation={1} sx={{ bgcolor: '#D7E2F5' }}>
      <Stack>
        <Typography color="primary" paddingLeft={2} paddingTop={2}>
          Veranstaltung
        </Typography>
        <Stack direction="row" spacing={2} padding={2} alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <CalendarToday sx={{ margin: 'auto !important' }} />
            <Stack>
              <Typography>Start: {veranstaltung.ablauf.at(0)?.start.toLocaleDateString() ?? '-'}</Typography>
              <Typography>Ende: {veranstaltung.ablauf.at(-1)?.end.toLocaleDateString() ?? '-'}</Typography>
            </Stack>
          </Stack>
          <VaStatusChip sapStatus={veranstaltung.sapStatus} vivaStatus={veranstaltung.vivaStatus} />
          <Stack>
            <Typography>SAP-ID (E): {veranstaltung.veranstaltungSapId ?? '-'}</Typography>
            <Typography>AKA-Veranstaltung-ID: {veranstaltung.akaVeranstaltungId}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Place sx={{ margin: 'auto !important' }} />
            <Stack>
              <Typography>{getRegionName(veranstaltung) ?? '-'}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Apartment sx={{ margin: 'auto !important' }} />
            <Stack>
              <Typography>{getOrtKuerzel(veranstaltung) ?? '-'}</Typography>
              <Typography>{getOrtBezeichnung(veranstaltung) ?? '-'}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Person sx={{ margin: 'auto !important' }} />
            <Typography>{firstExperteBlockung ? experteName(firstExperteBlockung.experte) : '-'}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Groups2 sx={{ margin: 'auto !important' }} />
            <Stack>
              <Typography>- gebucht</Typography>
              <Typography>- verfügbar</Typography>
              <Typography>- Warteliste</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};
