import { trpc } from '../../../../../../trpc';
import { formatTimeRangeForDate } from '../../../../../../utils/dateFormat';
import { type CalendarEvent, isExperteEvent, isStandortEvent } from '../helperClasses';
import { VeranstaltungEventButton } from './VeranstaltungEventButton';
import { Box, CircularProgress, Divider, Stack, Tooltip, Typography } from '@mui/material';

type VivaEventProps = {
  readonly calendarEvent: CalendarEvent;
  readonly color: string;
};

const veranstaltungQueryConfig = {
  retryOnMount: false,
  retry: false,
  refetchOnWindowFocus: false,
};

const printAkaVeranstaltungId = (calendarEvent: CalendarEvent): string => {
  if (isExperteEvent(calendarEvent)) {
    return calendarEvent.blockungData.quelleTerminId ?? '-';
  }

  return calendarEvent.blockungData.akaVeranstaltungId ?? '-';
};

const printGruppenraum = (calendarEvent: CalendarEvent): string => {
  if (isStandortEvent(calendarEvent) && (calendarEvent.blockungData.gruppenraumNotwendig?.length ?? 0) > 0) {
    return 'Ja';
  }

  return 'Nein';
};

const printRaumgroesse = (calendarEvent: CalendarEvent): string => {
  if (!isStandortEvent(calendarEvent)) {
    return '-';
  }

  if (calendarEvent.blockungData.raumgroesse === null) {
    return '-';
  }

  return calendarEvent.blockungData.raumgroesse.toString();
};

export const VivaEvent: React.FC<VivaEventProps> = ({ calendarEvent, color }: VivaEventProps) => {
  const data = calendarEvent.blockungData;

  // TODO Anna AKAVM-1392: Better typing to avoid the throw
  if (data.veranstaltungId === null) {
    throw new Error('[VivaEvent] VeranstaltungId is null');
  }

  const vaHoverInfoQuery = trpc.veranstaltung.getHoverInfoByVeranstaltungId.useQuery({ id: data.veranstaltungId }, veranstaltungQueryConfig);
  const vaProduktIdQuery = trpc.veranstaltung.getUrlInfoByVeranstaltungId.useQuery({ id: data.veranstaltungId }, veranstaltungQueryConfig);

  if (vaHoverInfoQuery.isLoading || vaProduktIdQuery.isLoading) {
    return <CircularProgress />;
  }

  const akaVeranstaltungId = printAkaVeranstaltungId(calendarEvent);
  const gruppenraum = printGruppenraum(calendarEvent);
  const raumgroesse = printRaumgroesse(calendarEvent);

  return (
    <Tooltip
      title={
        vaHoverInfoQuery.isSuccess ? (
          <Stack>
            <Typography variant="body2">{vaHoverInfoQuery.data.title}</Typography>
            <Typography variant="body2">AKA-Veranstaltung-ID: {akaVeranstaltungId}</Typography>
            <Typography variant="body2">SapId: {vaHoverInfoQuery.data.sapId ?? '-'}</Typography>
            <Typography variant="body2">Geschäftsbereich: {calendarEvent.blockungData.geschaeftsbereich}</Typography>
            <Typography variant="body2">Ablaufzeiten:</Typography>
            {calendarEvent.blockungData.zeitraeume.map(({ start, end }) => (
              <Typography key={Number(start)} variant="body2" paddingLeft={1}>
                {formatTimeRangeForDate(start, end)}
              </Typography>
            ))}
            {isStandortEvent(calendarEvent) && (
              <>
                <Divider />
                <Typography variant="body2">Gruppenraum: {gruppenraum}</Typography>
                <Typography variant="body2">Hotelqualifikation: siehe genereller Kommentar</Typography>
                <Typography variant="body2">Raumgröße: {raumgroesse}</Typography>
              </>
            )}
          </Stack>
        ) : (
          <Typography>Fehler beim Laden der Veranstaltung</Typography>
        )
      }
    >
      <Box>
        <VeranstaltungEventButton eventColor={color} themaSapId={vaProduktIdQuery.data?.produktid} veranstaltungId={data.veranstaltungId}>
          {vaHoverInfoQuery.isSuccess ? (
            <Stack>
              <Typography variant="body2" textAlign="left">
                {vaHoverInfoQuery.data.buchungsnummer}
              </Typography>
              <Typography variant="caption" fontSize=".5rem" textAlign="center">
                {isStandortEvent(calendarEvent) ? calendarEvent.blockungData.standortName : vaHoverInfoQuery.data.ortKuerzel}
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body2">Buchung</Typography>
          )}
        </VeranstaltungEventButton>
      </Box>
    </Tooltip>
  );
};
