import { type StatusChangeResult } from '../../dtos';
import { Box } from '@mui/material';

type SingleProps = {
  readonly veranstaltung: StatusChangeResult;
};

type SeveralProps = {
  readonly veranstaltungen: StatusChangeResult[];
};

export const SuccessMessageSingle: React.FC<SingleProps> = (props: SingleProps) => (
  <Box>
    <p>Update von Veranstaltung {props.veranstaltung.akaVeranstaltungId ?? props.veranstaltung.id} erfolgreich. </p>
    <ul>
      {props.veranstaltung.successMessages.map((msg) => (
        <li key={props.veranstaltung.id}>{msg}</li>
      ))}
    </ul>
  </Box>
);

export const SuccessMessageSeveral: React.FC<SeveralProps> = (props: SeveralProps) => (
  <Box>
    <p>Status erfolgreich bei folgenden Veranstaltungen geändert:</p>
    <ul>
      {props.veranstaltungen.map((veranstaltung) => (
        <li key={veranstaltung.id}>
          {veranstaltung.akaVeranstaltungId ?? veranstaltung.id}
          <ul>
            {veranstaltung.successMessages.map((msg) => (
              <li key={`${veranstaltung.id}-${msg}`}>{msg}</li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  </Box>
);

const ListOfFailureReasons: React.FC<SingleProps> = (props: SingleProps) => (
  <ul>
    {props.veranstaltung.errorMessages.map((errorMessage) => (
      <li key={`${props.veranstaltung.id} - ${errorMessage}`}>{errorMessage}</li>
    ))}
  </ul>
);

export const FailCheckMessageSingle: React.FC<SingleProps> = (props: SingleProps) => (
  <Box>
    <p>Die Status für Veranstaltung {props.veranstaltung.akaVeranstaltungId ?? props.veranstaltung.id} konnte nicht geändert werden. </p>
    <ul>
      <ListOfFailureReasons veranstaltung={props.veranstaltung} />
    </ul>
  </Box>
);

export const FailCheckMessageSeveral: React.FC<SeveralProps> = (props: SeveralProps) => (
  <Box>
    <p>Die Status konnten für folgende Veranstaltungen nicht geändert werden: </p>
    <ul>
      {props.veranstaltungen.map((veranstaltung) => (
        <li key={veranstaltung.id}>
          {veranstaltung.akaVeranstaltungId ?? veranstaltung.id}
          <ListOfFailureReasons veranstaltung={veranstaltung} />
        </li>
      ))}
    </ul>
  </Box>
);

export const failBeMessage = 'Backend-Fehler beim Ändern des Status.';
