import { type VivaUserScope, vivaUserScopes } from '../../dtos';
import { getItemFromSessionStorage, removeItemFromSessionStorage, setItemToSessionStorage } from '../../utils/sessionStorage';
import { AdminScopeSelect } from './AdminScopeSelect';
import { useAccount } from '@azure/msal-react';
import { Apps, Logout } from '@mui/icons-material';
import { AppBar, Box, Button, IconButton, MenuItem, Paper, Popper, Stack, Typography } from '@mui/material';
import { type PropsWithChildren, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

type HeaderProps = PropsWithChildren<{
  readonly userImageURL?: string;
}>;

export const HEADER_HEIGHT = 6;

const getAdminScopesInitially = (): VivaUserScope[] => {
  const scopes = getItemFromSessionStorage('X-Viva-Admin-Scopes');
  if (scopes === null) {
    return Object.values(vivaUserScopes);
  }

  return Object.values(scopes);
};

export const Header: React.FC<HeaderProps> = ({ children, userImageURL }: HeaderProps) => {
  const account = useAccount();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [selectedScopes, setSelectedScopes] = useState<VivaUserScope[]>(getAdminScopesInitially());

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  // set admin scopes to session storage if correct role
  useEffect(() => {
    if (account?.idTokenClaims?.roles?.includes('Admin')) {
      setItemToSessionStorage('X-Viva-Admin-Scopes', selectedScopes);
    } else {
      removeItemFromSessionStorage('X-Viva-Admin-Scopes');
    }
  }, [selectedScopes, account?.idTokenClaims?.roles]);

  return (
    <Box>
      <AppBar color="inherit" position="sticky" sx={{ height: `${HEADER_HEIGHT}vh` }}>
        <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mr: 2 }}>
          <Stack alignItems="center" direction="row" spacing={2}>
            <a href="/" style={{ textDecoration: 'none' }}>
              <img alt="Haufe Akademie Logo" src="/assets/images/haufe/Haufe_Akademie_Logo_negativ.svg" style={{ maxHeight: `${HEADER_HEIGHT}vh`, backgroundColor: '#075BFA' }} />
            </a>
            <Button onClick={handleClick} variant="text">
              <Apps fontSize="large" />
            </Button>
            <Popper id="apps-popper" open={open} anchorEl={anchorEl}>
              <Paper sx={{ padding: 2 }}>
                <MenuItem>
                  <Link onClick={() => setAnchorEl(null)} to="/reporting/appointmentCheck">
                    <Typography variant="body1">Terminanfrage</Typography>
                  </Link>
                </MenuItem>
              </Paper>
            </Popper>
            <a href="/" style={{ textDecoration: 'none' }}>
              <Typography color="primary" component="div" sx={{ flexGrow: 1 }} variant="h5">
                Viva
              </Typography>
            </a>
            {children}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <AdminScopeSelect selectedScopes={selectedScopes} setSelectedScopes={setSelectedScopes} account={account} />
            <Typography variant="h6" margin="auto">
              {account ? account.name?.replace(',', '').split(' ').reverse().join(' ') : account}
            </Typography>
            {userImageURL && <img alt="user" src={userImageURL} />}
            {account && (
              <IconButton sx={{ height: 'fit-content', margin: 'auto' }} href="/auth/logout">
                <Logout />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </AppBar>
    </Box>
  );
};
